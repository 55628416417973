function CartItemSide2Api(api_host) {
    this.api_host = api_host || location.origin;
}

CartItemSide2Api.prototype = {
    addItem: function(cart_item_id, customizer_data, callback, errorHandler) {
        var url = this.api_host +"/carts/items/" + cart_item_id + "/side2/add";
        var params = {
            style_id: customizer_data.styleId,
            design_id: customizer_data.designId,
            style_name: customizer_data.styleName,
            front_image: customizer_data.frontImage,
            back_image: customizer_data.backImage,
            left_image: customizer_data.leftImage,
            right_image: customizer_data.rightImage,
            builder_customization: JSON.stringify(customizer_data.builderCustomizations)
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateStyleId: function(cart_item_id, style_id, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/side2/update-style-id";
        var params = {
            style_id: style_id,
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateDesign: function(cart_item_id, design_id, builder_customization, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/side2/update-design";
        var params = {
            design_id: design_id,
            builder_customization: builder_customization,
            _method: "PUT"
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateThumbnails: function(cart_item_id, thumbnails, callback, errorHandler) {
        if (thumbnails === null) {
            thumbnails = {};
        }

        front = thumbnails.front !== undefined ? thumbnails.front : "";
        back = thumbnails.back !== undefined ? thumbnails.back : "";
        left = thumbnails.left !== undefined ? thumbnails.left : "";
        right = thumbnails.right !== undefined ? thumbnails.right : "";

        var url = this.api_host + "/carts/items/" + cart_item_id + "/side2/update-thumbnails";
        var params = {
            front_image: front,
            back_image: back,
            left_image: left,
            right_image: right,

            _method: 'PUT',
        };

        $.post(url, params, callback).fail(errorHandler);
    },

    updateApplicationSizes: function(cart_item_id, sizes, callback, errorHandler) {
        var url = this.api_host + "/carts/items/" + cart_item_id + "/side2/update-application-size";
        var params = {
            application_size: JSON.stringify(sizes),
            _method: "PUT",
        };

        $.post(url, params, callback).fail(errorHandler);
    }
};
